<template>
    <div class="page-container">
      <div class="header-area">
        <div class="tripple-dot">
          <div class="header-text">ประเมินผล</div>
        
        </div>
        
      </div>
      <div>
        <div class="body-area">
          <!-- <div class="label-subject">แบบประเมินความพึงพอใจ</div> -->
            </div>
      </div>
      <!-- {{ userId }} -->
      <div v-if="isSubmit">


        <div class="body-success">
          <img class="icon-success" src="../assets/success.png" />
          <div class="label-success">ขอบคุณที่ใช้บริการ</div>
            </div>
            <div class="footer-success">
          <div class="btn-request" v-on:click="close()">ปิดหน้าต่าง</div>
        </div>
      </div>
      <div v-if="!isSubmit">
        <div class="queation-container">
          <div class="row label-question">1.ความถูกต้องของข้อมูล</div>
          <div class="row">
            <input type="radio" id="A1" value="A" v-model="q1" />
            <label for="A1">พอใจมาก</label>
            <br/>
            <input type="radio" id="B1" value="B" v-model="q1" />
            <label for="B1">พอใจ</label>
            <br/>
            <input type="radio" id="C1" value="C" v-model="q1" />
            <label for="C1">พอใจน้อย</label>
            <br/>
            <input type="radio" id="D1" value="D" v-model="q1" />
            <label for="D1">ไม่พอใจ</label>
            <!-- <br/>
            <input type="radio" id="E1" value="E" v-model="q1" />
            <label for="E1">ไม่พอใจมาก</label> -->
          </div>
          <div class="row label-question">2.ความรวดเร็วในการค้นหาข้อมูล</div>
          <div class="row">
            <input type="radio" id="A2" value="A" v-model="q2" />
            <label for="A2">พอใจมาก</label>
            <br/>
            <input type="radio" id="B2" value="B" v-model="q2" />
            <label for="B2">พอใจ</label>
            <br/>
            <input type="radio" id="C2" value="C" v-model="q2" />
            <label for="C2">พอใจน้อย</label>
            <br/>
            <input type="radio" id="D2" value="D" v-model="q2" />
            <label for="D2">ไม่พอใจ</label>
            <!-- <br/>
            <input type="radio" id="E2" value="E" v-model="q2" />
            <label for="E2">ไม่พอใจมาก</label> -->
          </div>
          <div class="row label-question">3.ความสะดวกในการใช้งาน</div>
          <div class="row">
            <input type="radio" id="A3" value="A" v-model="q3" />
            <label for="A3">พอใจมาก</label>
            <br/>
            <input type="radio" id="B3" value="B" v-model="q3" />
            <label for="B3">พอใจ</label>
            <br/>
            <input type="radio" id="C3" value="C" v-model="q3" />
            <label for="C3">พอใจน้อย</label>
            <br/>
            <input type="radio" id="D3" value="D" v-model="q3" />
            <label for="D3">ไม่พอใจ</label>
            <br/>
            <!-- <input type="radio" id="E3" value="E" v-model="q3" />
            <label for="E3">ไม่พอใจมาก</label> -->
          </div>
          <div class="row label-question">4. ข้อเสนอแนะ</div>
          <div class="row">
            <textarea v-model="remark" class="input-question"></textarea>
          </div> 
        </div>
        <div v-if="isChecked()" class="footer">
          <div class="btn-request" v-on:click="onSubmit()">ส่งแบบประเมิน</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import liff from '@line/liff';
  import axios from 'axios';
  
  
  export default {
    name: 'FeedbackComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
        isUnauthorize: false,
        q1: "",
        q2: "",
        q3: "",
        remark: "",
        isSubmit: false
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        liff.init({
          liffId: '2001631857-RZd0qaGO', // Use own liffId
          withLoginOnExternalBrowser: true,
        }).then(() => {
          const idToken = liff.getDecodedIDToken();
          console.log(idToken); // print decoded idToken object
          if (idToken) {
            this.userId = idToken.sub;
            this.name = idToken.name;
            this.picture = idToken.picture;
          }
  
        });
      },
      onSubmit(){
        let payload = {
          userId: this.userId,
          name: this.name,
          img: this.picture,
          q1: this.q1,
          q2: this.q2,
          q3: this.q3,
          remark: this.remark
        }

        this.isSubmit = true;
        console.log('payload: ', payload);
        axios
          .post('https://api-moph.zensesage.cloud/line/feedback', payload)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => console.log('finally'))
      },
      close() {
        liff.closeWindow();
      },
      isChecked() {
        if (this.q1 != "" && this.q2 != "" && this.q3 != "") {
          return true;
        }
        return false;
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  .input-question {
    width: 100%;
    height: 100px;
  }
.header-text{
  color: white;
  font-size: 32px;
}
.page-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
  }
  
  .tripple-dot {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .blue {
    color: deepskyblue;
  }
  
  .mtop-small {
    margin-top: 20px;
  }
  
  .label-subject {
    /* text-align: left; */
    /* padding-left: 10px; */
  }
  
  .label-text {
    text-align: left;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;
      font-weight: bold;
  }
  .text-box {
    width: 90%;
      height: 35px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      padding-left: 10px;
      font-size: 16px;
  }
  
  .select-box {
    width: 93%;
      height: 43px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      background-color: white;
      padding-left: 10px;
      font-size: 16px;
  }
  .header-area {
    height: 85px;
    width: 100%;
    /* background-color: red; */
    background-image: url("../assets/headerbg.png");
    background-size: cover;
  }
  
  .btn-request {
    font-size: 16px;
      border-radius: 8px;
      background-color: deepskyblue;
      color: white;
      border: none;
      padding: 10px 15px;
  }
  
  .body-area {
    width: 100%;
    padding-top: 20px;
  }
  
  .profile-photo {
    /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
    border-radius: 50%;
      width: 70px;
      top: 40px;
      left: 50px;
      border: green 1px solid;
      position: absolute;
  }
  
  .phonebook-photo {
    /* border-radius: 50%; */
    /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
    width: 65px;
      position: absolute;
      top: 45px;
      right: 45px;
  }
  
  .circle-a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 150px; */
    left: calc(40% - 7px);
  }
  
  .circle-b {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 185px; */
    left: calc(50% - 7px);
  }
  
  .circle-c {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 220px; */
    left: calc(60% - 7px);
  }

  .queation-container {
    padding: 20px;
  }
  .row {
    width: 100%;
    padding: 15px 0 5px 0;
    text-align: left;
  }
  .label-question {
    color: black;
    font-weight: bold;
  }
  .footer {
    margin: 15px 25px;
  }
  .footer-success {
    margin: 15px 25px;
    margin-top: 100px;
  }
  .body-success {
    margin-top: 100px;
  }
  .icon-success {
    width: 128px;
  }
  .label-success {
    margin-top: 20px;
  }
  </style>
  