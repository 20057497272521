<template>
  <div v-if="isChecking">
    กรุณารอสักครู่...
  </div>
  <div v-if="!isChecking">
    <div v-if="isConsent" class="page-container">
      <div class="header-area">
          <img class="profile-photo-signed" :src="picture" />
        </div>
        <div class="mtop-medium">
          <div class="label-welcome">ขอบคุณค่ะ</div>
          <div class="mtop-small"></div>
        <div>คุณ <span class="green">{{  name }} </span> ได้ทำการยอมรับกฏหมาย พ.ร.บ คุ้มครองข้อมูลส่วนบุคคลแล้ว</div>
        <div class="mtop-small"></div>
        <div>{{  profile.org }}</div>
        <div class="mtop-medium"></div>
            <input class="mtop-small btn-request" @click="onCloseBtnClicked()" type="submit" value="ปิดหน้าต่าง">
      </div>
    </div>
      <div v-if="!isConsent" class="page-container">
        <div class="header-area">
          <img class="profile-photo" :src="picture" />
          <div class="tripple-dot">
            <div class="circle-a"></div>
          <div class="circle-b"></div>
          <div class="circle-c"></div>
          
          </div>
          <img class="phonebook-photo" src="../assets/pdpa.png" />
        </div>
        <div class="body-area">
          <div class="consent-area">
            <div class="label-topic">ข้อความประกาศในการนำส่ง นามานุกรม รูปแบบ E-book</div>
            <div class="label-description">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (Personal Data Protection Act: PDPA) เป็นกฎหมายป้องกันการละเมิดข้อมูลส่วนบุคคลของบุคคลธรรมดา ซึ่งข้อมูลส่วนบุคคล คือ ข้อมูลพื้นฐานที่บ่งบอกถึงเจ้าของข้อมูลนั้นๆ ทั้งทางตรงและทางอ้อม อาทิ ชื่อ-นามสกุล หมายเลขโทศัพท์มือถือ และที่อยู่ เป็นต้น รวมทั้งข้อมูลบุคคลที่มีความอ่อนไหว เช่น ศาสนา เชื้อชาติ ข้อมูลทางเพศ เป็นต้น การละเมิดหรือเผยแพร่ข้อมูลส่วนบุคล มีโทษทั้งทางแพ่ง ทางอาญา และทางปกครอง  </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;นามานุกรมกระทรวงสาธารณสุข ประจำปี 2567 มีวัตถุประสงค์การจัดทำเพื่อเป็นช่องทางการติดต่อประสานงานระหว่างบุคลากรของหน่วยงานภายใต้กระทรวงสาธารณสุข และภาคีเครือข่ายสาธารณสุข การนำข้อมูลส่วนบุคคลส่วนใดส่วนหนึ่งในนามานุกรมฯ ไปเผยแพร่โดยมีวัตถุประสงค์เพื่อก่อให้เกิดความเสียหายและละเมิดสิทธิส่วนบุคคล หรือการส่งต่อในเชิงพาณิชย์ อาจเข้าข่ายการกระทำความผิดตามที่ พรบ.คุ้มครองข้อมูลส่วนบุคคลฯ บัญญัติไว้ </p>
            </div>
            <div class="mtop-small2"></div>
            <div class="label-topic">ข้อความประกาศใน MOPH Phonebook Line Account</div>
            <div class="label-description">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้อมูลที่เผยแพร่ใน MOPH Phonebook Line นี้ ปฏิบัติตามข้อกำหนดของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (Personal Data Protection Act: PDPA)</p>
            </div>
            <div class="mtop-small"></div>
            <input class="mtop-small btn-request" @click="onSubmitBtnClicked()" type="submit" value="ยินยอม">
          </div>
        </div>
      </div>
  </div>
  </template>
  
  <script>
  import liff from '@line/liff';
  import axios from 'axios';
  
  export default {
    name: 'ConsentComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: '',
        isUnauthorize: false,
        profile: {
          isActive: false,
          name: "",
          phone: "",
          ext: "-",
          fax: "-",
          position: "-",
          org: ""
        },
        isCalled: "Pending",
        isChecking: true,
        isConsent: false
      }
    },
    async created() {
      this.init()
    // this.checkConsent();
    },
    methods: {
      init() {
        liff.init({
          liffId: '2001631857-oeDnL9b5', // Use own liffId
          withLoginOnExternalBrowser: true,
        }).then(() => {
          const idToken = liff.getDecodedIDToken();
          console.log(idToken); // print decoded idToken object
          if (idToken) {
            this.userId = idToken.sub;
            this.name = idToken.name;
            this.picture = idToken.picture;
            this.checkConsent();
          }
  
        });
      }, onCloseBtnClicked(){
        liff.closeWindow();
      }, onSubmitBtnClicked() {
        const payload = {
          uid: this.userId,
        }
        axios
          .post('https://api-moph.zensesage.cloud/line/consent', payload)
        //   .post('http://localhost:3000/line/consent', payload)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
            this.isCalled = error
          })
          .finally(() => this.checkConsent())
      }, checkConsent() {
        this.isCalled = "Called"
        
        axios
            .get('https://api-moph.zensesage.cloud/line/consent/'+this.userId)
        //   .get('http://localhost:3000/line/consent/'+this.userId)
          .then(response => {
            console.log(response.data)
            this.isConsent = response.data.isConsent
          })
          .catch(error => {
            console.log(error)
            this.isCalled = error
          })
          .finally(() => this.isChecking = false)
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.consent-area {
    padding-right: 20px;
}

p {
    text-align: justify;
}
  .label-topic {
    text-align: left;
    font-weight: bold;
  }

  .label-description {
    /* text-align: left; */
  }
  .green {
    color: green;
    font-weight: bold;
  }
  .page-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
  }
  
  .tripple-dot {
    position: absolute;
    top: 70px;
    width: 100%;
  }
  .blue {
    color: deepskyblue;
  }
  
  .mtop-small {
    margin-top: 20px;
  }
  
  .mtop-small2 {
    margin-top: 40px;
  }
  .label-subject {
    text-align: left;
    padding-left: 10px;
  }
  
  .label-text {
    text-align: left;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;
      font-weight: bold;
  }
  .text-box {
    width: 90%;
      height: 35px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      padding-left: 10px;
      font-size: 16px;
  }
  
  .select-box {
    width: 93%;
      height: 43px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      background-color: white;
      padding-left: 10px;
      font-size: 16px;
  }
  .header-area {
    height: 150px;
    width: 100%;
    /* background-color: red; */
    background-image: url("../assets/headerbg.png");
    background-size: cover;
  }
  
  .btn-request {
    font-size: 16px;
      border-radius: 8px;
      background-color: deepskyblue;
      color: white;
      border: none;
      padding: 10px 15px;
  }
  
  .body-area {
    width: 100%;
    margin: 10px;
    margin-right: 40px;
  }
  
  .profile-photo {
    /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
    border-radius: 50%;
      width: 70px;
      top: 40px;
      left: 50px;
      border: green 1px solid;
      position: absolute;
  }

  .profile-photo-signed {
    border-radius: 50%;
      width: 170px;
      top: 140px;
      left: 50%;
      transform: translate(-50%, -50%);
      border: green 1px solid;
      position: absolute;
  }
  
  .phonebook-photo {
    /* border-radius: 50%; */
    /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
    width: 80px;
      position: absolute;
      top: 35px;
      right: 45px;
  }
  
  .mtop-medium {
    margin-top: 120px;
  }
  .circle-a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 150px; */
    left: calc(40% - 7px);
  }
  
  .circle-b {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 185px; */
    left: calc(50% - 7px);
  }
  
  .circle-c {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 220px; */
    left: calc(60% - 7px);
  }
  .label-welcome {
    font-size: 28px;
    color: green;
    margin-top: 20px;
  }
  </style>
  