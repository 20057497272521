<template>
    <div class="page-container">
      <div>
        <img class="img-policy" src="https://cdn.zensesage.cloud/policy/policy1a.jpg" />
      </div>
      <div>
        <img class="img-policy" src="https://cdn.zensesage.cloud/policy/policy2a.jpg" />
      </div>
      <div>
        <img class="img-policy" src="https://cdn.zensesage.cloud/policy/policy3a.jpg" />
      </div>
      <div>
        <img class="img-policy" src="https://cdn.zensesage.cloud/policy/policy4a.jpg" />
      </div>

    </div>
  </template>
  
  <script>
//   import axios from 'axios';
  
  export default {
    name: 'PolicyComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
        isUnauthorize: false,
        phonebooks: [],
        org: "ALL",
        text: "",
        page: 1,
        pageSize: 20
      }
    },
    created() {
    },
    methods: {
      init() {
        
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.img-policy {
    width: 100%;
}
  
  </style>
  