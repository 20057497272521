<template>
  <div v-if="isModalEdit" class="modal-edit">
    <div class="label-header">แก้ไขข้อมูล</div>
    <div class="table-edit">
      <table>
        <tr>
          <td>ชื่อ - นามสกุล</td>
          <td><input class="input-modal" type="text" v-model="modify.name"></td>
        </tr>
        <tr>
          <td>เบอร์โทร</td>
          <td><input class="input-modal" type="text" v-model="modify.phone"></td>
        </tr>
        <tr>
          <td>เบอร์ภายใน</td>
          <td><input class="input-modal" type="text" v-model="modify.ext"></td>
        </tr>
        <tr>
          <td>โทรสาร</td>
          <td><input class="input-modal" type="text" v-model="modify.fax"></td>
        </tr>
        <tr>
          <td>ตำแหน่ง</td>
          <td><input class="input-modal" type="text" v-model="modify.position"></td>
        </tr>
        <tr>
          <td>หน่วยงาน</td>
          <td>
            <select class="modal-select" v-model="modify.org">
            <option value="สำนักปลัดกระทรวงสาธารณสุข">สำนักปลัดกระทรวงสาธารณสุข</option>
            <option value="กรมควบคุมโรค">กรมควบคุมโรค</option>
            <option value="กรมการแพทย์">กรมการแพทย์</option>
            <option value="กรมการแพทย์แผนไทยและการแพทย์ทางเลือก">กรมการแพทย์แผนไทยและการแพทย์ทางเลือก</option>
            <option value="กรมวิทยาศาสตร์การแพทย์">กรมวิทยาศาสตร์การแพทย์</option>
            <option value="กรมสนับสนุนบริการสุขภาพ">กรมสนับสนุนบริการสุขภาพ</option>
            <option value="กรมสุขภาพจิต">กรมสุขภาพจิต</option>
            <option value="กรมอนามัย">กรมอนามัย</option>
            <option value="สำนักงานคณะกรรมการอาหารและยา">สำนักงานคณะกรรมการอาหารและยา</option>
            <option value="รองอธิการบดีด้านบริหาร">รองอธิการบดีด้านบริหาร</option>
            <option value="องค์การเภสัชกรรม">องค์การเภสัชกรรม</option>
            <option value="เขตบริการสุขภาพ เขตที่ 1">เขตบริการสุขภาพ เขตที่ 1</option>
            <option value="เขตบริการสุขภาพ เขตที่ 2">เขตบริการสุขภาพ เขตที่ 2</option>
            <option value="เขตบริการสุขภาพ เขตที่ 3">เขตบริการสุขภาพ เขตที่ 3</option>
            <option value="เขตบริการสุขภาพ เขตที่ 4">เขตบริการสุขภาพ เขตที่ 4</option>
            <option value="เขตบริการสุขภาพ เขตที่ 5">เขตบริการสุขภาพ เขตที่ 5</option>
            <option value="เขตบริการสุขภาพ เขตที่ 6">เขตบริการสุขภาพ เขตที่ 6</option>
            <option value="เขตบริการสุขภาพ เขตที่ 7">เขตบริการสุขภาพ เขตที่ 7</option>
            <option value="เขตบริการสุขภาพ เขตที่ 8">เขตบริการสุขภาพ เขตที่ 8</option>
            <option value="เขตบริการสุขภาพ เขตที่ 9">เขตบริการสุขภาพ เขตที่ 9</option>
            <option value="เขตบริการสุขภาพ เขตที่ 10">เขตบริการสุขภาพ เขตที่ 10</option>
            <option value="เขตบริการสุขภาพ เขตที่ 11">เขตบริการสุขภาพ เขตที่ 11</option>
            <option value="เขตบริการสุขภาพ เขตที่ 12">เขตบริการสุขภาพ เขตที่ 12</option>
          </select></td>
        </tr>
      </table>
      <div class="mtop-small">
        <input class="btn-ok" type="submit" v-on:click="onClickSave()" value="บันทึก">
        <input class="btn-cancel" type="submit" v-on:click="onClickCancel()" value="ยกเลิก">
      </div>
      
    </div>
  </div>
  <div v-if="isModalDelete" class="modal-delete">
    <div class="label-header">ลบข้อมูล</div>
    <div class="table-edit mtop-medium">
      คุณยืนยันที่จะลบข้อมูลของ <span class="label-delete">{{  modify.name }}</span>?
      <div class="mtop-medium">
        <input class="btn-ok" type="submit" v-on:click="onClickSave()" value="บันทึก">
        <input class="btn-cancel" type="submit" v-on:click="onClickCancel()" value="ยกเลิก">
      </div>
      
    </div>
  </div>
  <div v-if="isModal" class="modal" @click="closeModal()"></div>
  <div class="page-container">
    <div>
      <div class="body-area">
        <input class="search-box" v-model="text" @keydown.enter.prevent="onSearchButtonClicked()" v-on:change="onSearchTextChange()" type="text" placeholder="ค้นหาที่นี่">
        <!-- <input class="text-box" type="text" placeholder="หน่วยงาน"> -->
        <select class="search-select" v-model="org">
          <option value="ALL" selected>ทุกหน่วยงาน</option>
          <option value="สำนักปลัดกระทรวงสาธารณสุข">สำนักปลัดกระทรวงสาธารณสุข</option>
          <option value="กรมควบคุมโรค">กรมควบคุมโรค</option>
          <option value="กรมการแพทย์">กรมการแพทย์</option>
          <option value="กรมการแพทย์แผนไทยและการแพทย์ทางเลือก">กรมการแพทย์แผนไทยและการแพทย์ทางเลือก</option>
          <option value="กรมวิทยาศาสตร์การแพทย์">กรมวิทยาศาสตร์การแพทย์</option>
          <option value="กรมสนับสนุนบริการสุขภาพ">กรมสนับสนุนบริการสุขภาพ</option>
          <option value="กรมสุขภาพจิต">กรมสุขภาพจิต</option>
          <option value="กรมอนามัย">กรมอนามัย</option>
          <option value="สำนักงานคณะกรรมการอาหารและยา">สำนักงานคณะกรรมการอาหารและยา</option>
          <option value="รองอธิการบดีด้านบริหาร">รองอธิการบดีด้านบริหาร</option>
          <option value="องค์การเภสัชกรรม">องค์การเภสัชกรรม</option>
          <option value="เขตบริการสุขภาพ เขตที่ 1">เขตบริการสุขภาพ เขตที่ 1</option>
          <option value="เขตบริการสุขภาพ เขตที่ 2">เขตบริการสุขภาพ เขตที่ 2</option>
          <option value="เขตบริการสุขภาพ เขตที่ 3">เขตบริการสุขภาพ เขตที่ 3</option>
          <option value="เขตบริการสุขภาพ เขตที่ 4">เขตบริการสุขภาพ เขตที่ 4</option>
          <option value="เขตบริการสุขภาพ เขตที่ 5">เขตบริการสุขภาพ เขตที่ 5</option>
          <option value="เขตบริการสุขภาพ เขตที่ 6">เขตบริการสุขภาพ เขตที่ 6</option>
          <option value="เขตบริการสุขภาพ เขตที่ 7">เขตบริการสุขภาพ เขตที่ 7</option>
          <option value="เขตบริการสุขภาพ เขตที่ 8">เขตบริการสุขภาพ เขตที่ 8</option>
          <option value="เขตบริการสุขภาพ เขตที่ 9">เขตบริการสุขภาพ เขตที่ 9</option>
          <option value="เขตบริการสุขภาพ เขตที่ 10">เขตบริการสุขภาพ เขตที่ 10</option>
          <option value="เขตบริการสุขภาพ เขตที่ 11">เขตบริการสุขภาพ เขตที่ 11</option>
          <option value="เขตบริการสุขภาพ เขตที่ 12">เขตบริการสุขภาพ เขตที่ 12</option>
        </select>
        
        <input class="btn-request" type="submit" v-on:click="onSearchButtonClicked()" value="ค้นหา"> <input class="btn-create" type="submit" v-on:click="onCreateBtnClicked()" value="เพิ่มรายชื่อ">
      </div>
    </div>
    <div class="table-result mtop-small">
      <table id="customers">
  <tr>
    <th>#</th>
    <th class="th-name">ชื่อ - นามสกุล</th>
    <th class="th-phone">เบอร์โทร</th>
    <th class="th-ext">เบอร์ภายใน</th>
    <th class="th-fax">โทรสาร</th>
    <th class="th-position">ตำแหน่ง</th>
    <th class="th-org">หน่วยงาน</th>
    <th class="th-modify">จัดการ</th>
  </tr>
  <tr  v-bind:key="item.key" v-for="item, key in phonebooks">
    <td>{{ key+1 }}</td>
    <td>{{ item.name }}</td>
    <td>{{ item.phone }}</td>
    <td>{{ item.ext }}</td>
    <td>{{ item.fax }}</td>
    <td>{{ item.position }}</td>
    <td>{{ item.org }}</td>
    <td><span class="clickable" @click="onEditClicked(item)">แก้ไข</span> | <span class="clickable red" @click="onDeleteClicked(item)">ลบ</span></td>
  </tr>
</table>
<div v-if="isNotFound()" class="data-notfound">ไม่พบข้อมูล</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AdminSearchComponent',
  props: {
    msg: String
  }, data() {
    return {
      userId: '', // sub
      name: '',
      picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
      isUnauthorize: false,
      phonebooks: [],
      org: "ALL",
      text: "",
      page: 1,
      pageSize: 300,
      isModal: false,
      isModalEdit: false,
      isModalDelete: false,
      modalMode: 1, // 0 = Create, 1 = Edit, 2 = Delete
      modify: {
        name: "",
        phone: "",
        ext: "",
        fax: "",
        position: "",
        org: ""
      }
    }
  },
  created() {
    //   this.init()
    const initData = {
      "text":"",
      "org": "สำนักปลัดกระทรวงสาธารณสุข",
      "page": this.page,
      "pageSize": this.pageSize,
      "isAdmin": true
    }
    this.search(initData);
  },
  methods: {
    init() {
      // liff.init({
      //   liffId: '2001631857-bMdnrM7o', // Use own liffId
      //   withLoginOnExternalBrowser: true,
      // }).then(() => {
      //   const idToken = liff.getDecodedIDToken();
      //   console.log(idToken); // print decoded idToken object
      //   if (idToken) {
      //     this.userId = idToken.sub;
      //     this.name = idToken.name;
      //     this.picture = idToken.picture;
      //   }

      // });
    },
    onSearchTextChange() {
      // if (this.text.length >= 3) {
      //   this.onSearchButtonClicked();
      // }
    },
    onSearchButtonClicked() {
      let payload = {
        "text": this.text,
        "org": this.org,
        "page": this.page,
        "pageSize": this.pageSize,
        "isAdmin": true
      }
      this.search(payload);
    },
    isNotFound() {
      return this.phonebooks.length == 0;
    },
    search(data) {
      axios
          .post('https://api-moph.zensesage.cloud/web/search', data)
          .then(response => {
            console.log(response.data)
            this.phonebooks = response.data.data;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => console.log('finally'))
    },
    create(data) {
      axios
          .post('https://api-moph.zensesage.cloud/admin/phonebook', data)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.onSearchButtonClicked())
    },
    update(data) {
      axios
          .put('https://api-moph.zensesage.cloud/admin/phonebook', data)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.onSearchButtonClicked())
    },
    delete(data) {
      axios
          .delete('https://api-moph.zensesage.cloud/admin/phonebook', { data: data })
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.onSearchButtonClicked())
    },
    onClickSave() {
      if (this.isModalDelete) {
        // Delete data
        // let payload = this.modify;
        this.delete(this.modify);
        console.log('Delete mode', this.modify);
      } else {
        if (this.modalMode == 0) {
          // Create
          this.create(this.modify);
          console.log('Create mode', this.modify);
        } else {
          // Modify data
          this.update(this.modify);
          console.log('Modify mode', this.modify);
        }
        
      }
      // Call update
      this.closeModal();
    },
    onClickCancel() {
      this.closeModal();
    },
    closeModal() {
      this.isModal = false;
      this.isModalEdit = false;
      this.isModalDelete = false;
    },onEditClicked(item) {
      if (item) {
        this.modify = item;
        this.modalMode = 1;
      } else {
        this.modify= {
          name: "",
          phone: "",
          ext: "",
          fax: "",
          position: "",
          org: ""
        }
        this.modalMode = 0;
      }
      this.isModal = true;
      this.isModalEdit = true;
      this.isModalDelete = false;
      console.log('On edit -> ', this.modify)
    }, onDeleteClicked(item) {
      this.modify = item;
      this.isModal = true;
      this.isModalEdit = false;
      this.isModalDelete = true;
      console.log('On delete -> ', this.modify)
    },onCreateBtnClicked(){
      this.onEditClicked(false);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  
}
.table-result {
  margin: 20px;
}
td {
  text-align: left;
}
.th-name {
  min-width: 235px;
}

.th-phone {
  min-width: 100px;
}

.th-ext {
  min-width: 100px;
}

.th-fax {
  min-width: 90px;
}

.th-position {
  min-width: 520px;
}
.th-org {
  min-width: 210px;
}

.th-modify {
  min-width: 100px;
}
.clickable {
  color: blue;
}
.page-container {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  /* background-image: url('../assets/bg-white.jpg');
  background-size: cover; */
}

.tripple-dot {
  position: absolute;
  top: 70px;
  width: 100%;
}
.blue {
  color: deepskyblue;
}

.mtop-small {
  margin-top: 20px;
}

.label-subject {
  text-align: left;
  padding-left: 10px;
}

.label-text {
  text-align: left;
    padding-left: 15px;
    margin-top: 15px;
    font-size: 13px;
    font-weight: bold;
}
.text-box {
  width: 90%;
    height: 35px;
    border-radius: 8px;
    border: 1px solid lightslategrey;
    padding-left: 10px;
    font-size: 16px;
}

.select-box {
  width: 93%;
    height: 43px;
    border-radius: 8px;
    border: 1px solid lightslategrey;
    background-color: white;
    padding-left: 10px;
    font-size: 16px;
}

.search-box {
  height: 37px;
  position: relative;
  top: -0.5px;
  
    /* border-radius: 8px; */
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border: 1px solid lightslategrey;
    border-right: none;
    padding-left: 10px;
    font-size: 16px;
    width:50%;
}

.search-select {
  height: 41px;
  /* margin-top: 1px; */
  /* line-height: 31px; */
    /* border-radius: 8px; */
    /* border-bottom-right-radius: 8px;
    border-top-right-radius: 8px; */
    border: 1px solid lightslategrey;
    background-color: white;
    padding-left: 10px;
    font-size: 16px;
}
.header-area {
  height: 150px;
  width: 100%;
  /* background-color: red; */
  background-image: url("../assets/headerbg.png");
  background-size: cover;
}

.btn-request {
  font-size: 16px;
  line-height: 21px;
    /* border-radius: 8px; */
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color: deepskyblue;
    color: white;
    border: none;
    padding: 10px 35px;
}

.btn-create {
  font-size: 16px;
  line-height: 21px;
    border-radius: 8px;
    /* border-bottom-right-radius: 8px;
    border-top-right-radius: 8px; */
    background-color: rgb(17, 196, 1);
    color: white;
    border: none;
    padding: 10px 35px;
    margin-right: 20px; 
}

.btn-ok {
  font-size: 16px;
  line-height: 21px;
    border-radius: 8px;
    /* border-bottom-right-radius: 8px;
    border-top-right-radius: 8px; */
    background-color: deepskyblue;
    color: white;
    border: none;
    padding: 10px 35px;
    margin-right: 20px; 
}

.btn-cancel {
  font-size: 16px;
  line-height: 21px;
    border-radius: 8px;
    /* border-bottom-right-radius: 8px;
    border-top-right-radius: 8px; */
    background-color: red;
    color: white;
    border: none;
    padding: 10px 35px;
}

.body-area {
  width: 100%;
  padding-top: 20px;
}

.profile-photo {
  /* border-radius: 50%;
  width: 70px;
  margin-top: 40px;
  margin-left: -200px;
  border: green 1px solid; */
  border-radius: 50%;
    width: 70px;
    top: 40px;
    left: 50px;
    border: green 1px solid;
    position: absolute;
}

.phonebook-photo {
  /* border-radius: 50%; */
  /* width: 65px;
  margin-top: 43px;
  margin-left: 150px;
  position: absolute; */
  width: 65px;
    position: absolute;
    top: 45px;
    right: 45px;
}

.circle-a {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
  left: 150px; */
  left: calc(40% - 7px);
}

.circle-b {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
  left: 185px; */
  left: calc(50% - 7px);
}

.circle-c {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
  left: 220px; */
  left: calc(60% - 7px);
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
}

#customers td, #customers th {
  border: 1px solid #e5ffd8;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #edfffe;}

#customers tr:hover {background-color: #d5ffc0; color: green}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0497aa;
  color: white;
  text-align: center;
}
.data-notfound {
  text-align: center;
  margin-top: 10px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.modal {
  position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 5000;
}

.modal-edit {
  border-radius: 8px;
  width: 500px;
  height: 350px;
  background-color: white;
  z-index: 9999;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 25px;
}

.modal-create {
  border-radius: 8px;
  width: 500px;
  height: 350px;
  background-color: white;
  z-index: 9999;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 25px;
}

.modal-delete {
  border-radius: 8px;
  width: 500px;
  height: 200px;
  background-color: white;
  z-index: 9999;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 25px;
}

.label-header {
  font-size: 30px;
}

.input-modal {
  height: 30px;
  border: 1px solid lightslategrey;
  border-radius: 8px;
  width: 100%;
  margin-left: 20px;
}
.center {
  text-align: center;
}
.modal-select {
    width: calc(100% + 5px);
    height: 35px;
    border-radius: 8px;
    border: 1px solid lightslategrey;
    background-color: white;
    padding-left: 10px;
    font-size: 16px;
    margin-left: 20px;
}
.table-edit {
  margin-left: 10px;
  margin-top: 20px;
}

.mtop-medium {
  margin-top: 50px;
}

.clickable {
  cursor: pointer;
}
.label-delete {
  color: red;
  font-weight: bold;
}

.red {
  color: red;
}
th { position: sticky; top: 0; }
</style>
