<template>
    <div class="page-container">
      <div class="header-area">
        <div class="tripple-dot">
          <div class="header-text">ติดต่อทีมงาน</div>
        
        </div>
        
      </div>
      <div>
        <div class="body-area">
          <img class="email-icon" src="../assets/gmail.png" />
          <div class="mtop-small label-subject">ท่านสามารถติดต่อทีมงานได้ที่ </div>
          <br />
          <a href="mailto:diary.moph@gmail.com">diary.moph@gmail.com</a>
            </div>
      </div>
      {{ userId }}
  
    </div>
  </template>
  
  <script>
  import liff from '@line/liff';
  
  
  
  export default {
    name: 'ContactComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
        isUnauthorize: false,
      }
    },
    created() {
      // this.init()
    },
    methods: {
      init() {
        liff.init({
          liffId: '2001631857-bMdnrM7o', // Use own liffId
          withLoginOnExternalBrowser: true,
        }).then(() => {
          const idToken = liff.getDecodedIDToken();
          console.log(idToken); // print decoded idToken object
          if (idToken) {
            this.userId = idToken.sub;
            this.name = idToken.name;
            this.picture = idToken.picture;
          }
  
        });
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.header-text{
  color: white;
  font-size: 32px;
}
.page-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
  }
  
  .tripple-dot {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .blue {
    color: deepskyblue;
  }
  
  .mtop-small {
    margin-top: 20px;
  }
  
  .label-subject {
    /* text-align: left; */
    /* padding-left: 10px; */
  }
  
  .label-text {
    text-align: left;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;
      font-weight: bold;
  }
  .text-box {
    width: 90%;
      height: 35px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      padding-left: 10px;
      font-size: 16px;
  }
  
  .select-box {
    width: 93%;
      height: 43px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      background-color: white;
      padding-left: 10px;
      font-size: 16px;
  }
  .header-area {
    height: 85px;
    width: 100%;
    /* background-color: red; */
    background-image: url("../assets/headerbg.png");
    background-size: cover;
  }
  
  .btn-request {
    font-size: 16px;
      border-radius: 8px;
      background-color: deepskyblue;
      color: white;
      border: none;
      padding: 10px 15px;
  }
  
  .body-area {
    width: 100%;
    padding-top: 20px;
  }
  
  .profile-photo {
    /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
    border-radius: 50%;
      width: 70px;
      top: 40px;
      left: 50px;
      border: green 1px solid;
      position: absolute;
  }
  
  .phonebook-photo {
    /* border-radius: 50%; */
    /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
    width: 65px;
      position: absolute;
      top: 45px;
      right: 45px;
  }

  .email-icon {
    width: 128px;
  }
  
  .circle-a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 150px; */
    left: calc(40% - 7px);
  }
  
  .circle-b {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 185px; */
    left: calc(50% - 7px);
  }
  
  .circle-c {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 220px; */
    left: calc(60% - 7px);
  }
  </style>
  