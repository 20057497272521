import { createWebHistory, createRouter } from "vue-router";
import HomeComponent from "@/components/HomeComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import EditComponent from "@/components/EditComponent.vue";
import FeedbackComponent from "@/components/FeedbackComponent.vue";
import AdminFeedbackComponent from "@/components/AdminFeedbackComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import AdminSearchComponent from "@/components/AdminSearchComponent.vue";
import PolicyComponent from "@/components/PolicyComponent.vue";
import ManualComponent from "@/components/ManualComponent.vue";
import ConsentComponent from "@/components/ConsentComponent.vue";
import AdminEditComponent from "@/components/AdminEditComponent.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeComponent,
  },{
    path: "/profile",
    name: "Profile",
    component: ProfileComponent,
  },{
    path: "/contact",
    name: "Contact",
    component: ContactComponent,
  },{
    path: "/edit",
    name: "Edit",
    component: EditComponent,
  },{
    path: "/feedback",
    name: "Feedback",
    component: FeedbackComponent,
  },{
    path: "/policy",
    name: "Policy",
    component: PolicyComponent,
  },{
    path: "/manual",
    name: "Manual",
    component: ManualComponent,
  },{
    path: "/consent",
    name: "Consent",
    component: ConsentComponent,
  },{
    path: "/admin/feedback",
    name: "Feedback Repoer",
    component: AdminFeedbackComponent,
  },{
    path: "/admin/search",
    name: "Admin Search",
    component: AdminSearchComponent,
  }, {
    path: "/admin/edit",
    name: "Admin Edit",
    component: AdminEditComponent,
  } 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;