<template>
    <div class="page-container">
      <div class="header-area">
        <div class="tripple-dot">
          <div class="header-text">รายงานผลประเมิน</div>
        
        </div>
        
      </div>
      <div>
        <div class="body-area">
          <!-- <div class="label-subject">แบบประเมินความพึงพอใจ</div> -->
            </div>
      </div>
      {{ userId }}
      <table class="table-report">
        <tr class="row-header">
            <td>ลำดับ</td>
            <td>รูปโปรไฟล์</td>
            <!-- <td>ยูไอดี</td> -->
            <td>ชื่อโปรไฟล์</td>
            <td>ความถูกต้องของข้อมูล</td>
            <td>ความรวดเร็วในการค้นหาข้อมูล</td>
            <td>ความสะดวกในการใช้งาน</td>
            <td>ข้อเสนอแนะ</td>
            <td>วันที่ทำการประเมิน</td>
        </tr>
        <tr v-bind:key="item.key" :class="getRowClass(key)" v-for="item, key in feedbacks">
            <td>{{key+1}}</td>
            <td><img class="profile-picture" :src="item.img"></td>
            <!-- <td>{{ item.userId }}</td> -->
            <td>{{ item.name }}</td>
            <td :class="getScoreClass(item.q1)">{{ convertScore(item.q1) }}</td>
            <td :class="getScoreClass(item.q2)">{{ convertScore(item.q2) }}</td>
            <td :class="getScoreClass(item.q3)">{{ convertScore(item.q3) }}</td>
            <td>{{ item.remark }}</td>
            <td>{{ getDate(item.createdDate) }}</td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  import liff from '@line/liff';
  import axios from 'axios';
  
  
  export default {
    name: 'AdminFeedbackComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
        isUnauthorize: false,
        feedbacks: []

      }
    },
    created() {
    //   this.init()
    axios
          .get('https://api-moph.zensesage.cloud/line/feedback')
          .then(response => {
            console.log(response.data)
            this.feedbacks = response.data;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => console.log('finally'))
    },
    methods: {
      init() {
        liff.init({
          liffId: '2001631857-RZd0qaGO', // Use own liffId
          withLoginOnExternalBrowser: true,
        }).then(() => {
          const idToken = liff.getDecodedIDToken();
          console.log(idToken); // print decoded idToken object
          if (idToken) {
            this.userId = idToken.sub;
            this.name = idToken.name;
            this.picture = idToken.picture;
          }
  
        });
      },
      onSubmit(){
        let payload = {
          userId: this.userId,
          name: this.name,
          img: this.picture,
          q1: this.q1,
          q2: this.q2,
          q3: this.q3
        }

        this.isSubmit = true;
        console.log('payload: ', payload);
        
      },
      close() {
        liff.closeWindow();
      },
      isChecked() {
        if (this.q1 != "" && this.q2 != "" && this.q3 != "") {
          return true;
        }
        return false;
      },
      convertScore(score) {
        switch(score) {
            case "A": return "พอใจมาก";
            case "B": return "พอใจ";
            case "C": return "พอใจน้อย";
            case "D": return "ไม่พอใจ";
            case "E": return "ไม่พอใจมาก";
            default: return "พอใจมาก";
        }
      },
      getRowClass(num) {
        if (num % 2 == 0) {
            return 'line-light';
        }
        return 'line-dark';
      },
      getScoreClass(score) {
        switch(score) {
            case "A": return "score-a";
            case "B": return "score-b";
            case "C": return "score-c";
            case "D": return "score-d";
            case "E": return "score-e";
            default: return "";
        }
      },
      getDate(date){
        var d = new Date(date);
        date = d.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' })
        date = date.replace('T', ' ');
        date = date.split('.')[0];
        return date;
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

.row-header {
    background-color: green;
    color: white;
    padding: 10px 0;
}
.score-a {
color: green;
}
.score-b {
    color: greenyellow;
}
.score-c {
    color: yellow;
}
.score-d {
    color: goldenrod;
}
.score-e {
    color: red;
}
.line-dark {
    background-color: rgb(241, 255, 245);
}

.line-light {
    background-color: white;
}
.header-text{
  color: white;
  font-size: 32px;
}
.page-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
  }
  
  .tripple-dot {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .blue {
    color: deepskyblue;
  }
  
  .mtop-small {
    margin-top: 20px;
  }
  
  .label-subject {
    /* text-align: left; */
    /* padding-left: 10px; */
  }
  
  .label-text {
    text-align: left;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;
      font-weight: bold;
  }
  .text-box {
    width: 90%;
      height: 35px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      padding-left: 10px;
      font-size: 16px;
  }
  
  .select-box {
    width: 93%;
      height: 43px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      background-color: white;
      padding-left: 10px;
      font-size: 16px;
  }
  .header-area {
    height: 85px;
    width: 100%;
    /* background-color: red; */
    background-image: url("../assets/headerbg.png");
    background-size: cover;
  }
  
  .btn-request {
    font-size: 16px;
      border-radius: 8px;
      background-color: deepskyblue;
      color: white;
      border: none;
      padding: 10px 15px;
  }
  
  .body-area {
    width: 100%;
    padding-top: 20px;
  }
  
  .profile-photo {
    /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
    border-radius: 50%;
      width: 70px;
      top: 40px;
      left: 50px;
      border: green 1px solid;
      position: absolute;
  }
  
  .phonebook-photo {
    /* border-radius: 50%; */
    /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
    width: 65px;
      position: absolute;
      top: 45px;
      right: 45px;
  }
  
  .circle-a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 150px; */
    left: calc(40% - 7px);
  }
  
  .circle-b {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 185px; */
    left: calc(50% - 7px);
  }
  
  .circle-c {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 220px; */
    left: calc(60% - 7px);
  }

  .queation-container {
    padding: 20px;
  }
  .row {
    width: 100%;
    padding: 15px 0 5px 0;
    text-align: left;
  }
  .label-question {
    color: black;
    font-weight: bold;
  }
  .footer {
    margin: 15px 25px;
  }
  .footer-success {
    margin: 15px 25px;
    margin-top: 100px;
  }
  .body-success {
    margin-top: 100px;
  }
  .icon-success {
    width: 128px;
  }
  .label-success {
    margin-top: 20px;
  }
  .profile-picture {
    width: 70px;
    border-radius: 50%;
  }
  .row-header {
    height: 32px;
  }
  .table-report {
    width: 100%;
  }
  </style>
  