<template>
  <div v-if="isChecking">
    กรุณารอสักครู่...
  </div>
  <div v-if="!isChecking">
    <div v-if="profile.isActive" class="page-container">
      <div class="header-area">
          <img class="profile-photo-signed" :src="picture" />
        </div>
        <div class="mtop-medium">
          <div class="label-welcome">สวัสดี</div>
          <div class="mtop-small"></div>
        <div>คุณ {{  profile.name }}</div>
        <div class="mtop-small"></div>
        <div>{{  profile.org }}</div>
      </div>
    </div>
      <div v-if="!profile.isActive" class="page-container">
        <div class="header-area">
          <img class="profile-photo" :src="picture" />
          <div class="tripple-dot">
            <div class="circle-a"></div>
          <div class="circle-b"></div>
          <div class="circle-c"></div>
          
          </div>
          <img class="phonebook-photo" src="../assets/phonebook.png" />
        </div>
        <div>
          <div class="body-area">
            <div class="label-subject">คุณ <span class="blue">{{ name }}</span> สามารถลงทะเบียนเพื่อเข้าถึงข้อมูลเพิ่มเติมได้โดยส่งแบบฟอร์มด้านล่าง</div>
            <div class="label-text">ชื่อ นามสกุล</div>
            <input class="text-box" v-model="profile.name" type="text" placeholder="ชื่อ นามสกุล">
            <div class="label-text">เบอร์โทร</div>
            <input class="text-box" v-model="profile.phone" type="text" placeholder="เบอร์โทร">
            <div class="label-text">หน่วยงาน</div>
            <!-- <input class="text-box" type="text" placeholder="หน่วยงาน"> -->
            <select v-model="profile.org" class="select-box">
              <option value="สำนักปลัดกระทรวงสาธารณสุข">สำนักปลัดกระทรวงสาธารณสุข</option>
              <option value="กรมควบคุมโรค">กรมควบคุมโรค</option>
              <option value="กรมการแพทย์">กรมการแพทย์</option>
              <option value="กรมการแพทย์แผนไทยและการแพทย์ทางเลือก">กรมการแพทย์แผนไทยและการแพทย์ทางเลือก</option>
              <option value="กรมวิทยาศาสตร์การแพทย์">กรมวิทยาศาสตร์การแพทย์</option>
              <option value="กรมสนับสนุนบริการสุขภาพ">กรมสนับสนุนบริการสุขภาพ</option>
              <option value="กรมสุขภาพจิต">กรมสุขภาพจิต</option>
              <option value="กรมอนามัย">กรมอนามัย</option>
              <option value="สำนักงานคณะกรรมการอาหารและยา">สำนักงานคณะกรรมการอาหารและยา</option>
              <option value="รองอธิการบดีด้านบริหาร">รองอธิการบดีด้านบริหาร</option>
              <option value="องค์การเภสัชกรรม">องค์การเภสัชกรรม</option>
              <option value="เขตบริการสุขภาพ เขตที่ 1">เขตบริการสุขภาพ เขตที่ 1</option>
              <option value="เขตบริการสุขภาพ เขตที่ 2">เขตบริการสุขภาพ เขตที่ 2</option>
              <option value="เขตบริการสุขภาพ เขตที่ 3">เขตบริการสุขภาพ เขตที่ 3</option>
              <option value="เขตบริการสุขภาพ เขตที่ 4">เขตบริการสุขภาพ เขตที่ 4</option>
              <option value="เขตบริการสุขภาพ เขตที่ 5">เขตบริการสุขภาพ เขตที่ 5</option>
              <option value="เขตบริการสุขภาพ เขตที่ 6">เขตบริการสุขภาพ เขตที่ 6</option>
              <option value="เขตบริการสุขภาพ เขตที่ 7">เขตบริการสุขภาพ เขตที่ 7</option>
              <option value="เขตบริการสุขภาพ เขตที่ 8">เขตบริการสุขภาพ เขตที่ 8</option>
              <option value="เขตบริการสุขภาพ เขตที่ 9">เขตบริการสุขภาพ เขตที่ 9</option>
              <option value="เขตบริการสุขภาพ เขตที่ 10">เขตบริการสุขภาพ เขตที่ 10</option>
              <option value="เขตบริการสุขภาพ เขตที่ 11">เขตบริการสุขภาพ เขตที่ 11</option>
              <option value="เขตบริการสุขภาพ เขตที่ 12">เขตบริการสุขภาพ เขตที่ 12</option>
            </select>
            <div class="mtop-small"></div>
            <input class="mtop-small btn-request" @click="onSubmitBtnClicked()" type="submit" value="ส่งแบบฟอร์ม">
          </div>
        </div>
      </div>
  </div>
  </template>
  
  <script>
  import liff from '@line/liff';
  import axios from 'axios';
  
  export default {
    name: 'ProfileComponent',
    props: {
      msg: String
    }, data() {
      return {
        userId: '', // sub
        name: '',
        picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
        isUnauthorize: false,
        profile: {
          isActive: false,
          name: "",
          phone: "",
          ext: "-",
          fax: "-",
          position: "-",
          org: ""
        },
        isCalled: "Pending",
        isChecking: true
      }
    },
    async created() {
      this.init()
    },
    methods: {
      init() {
        liff.init({
          liffId: '2001631857-bMdnrM7o', // Use own liffId
          withLoginOnExternalBrowser: true,
        }).then(() => {
          const idToken = liff.getDecodedIDToken();
          console.log(idToken); // print decoded idToken object
          if (idToken) {
            this.userId = idToken.sub;
            this.name = idToken.name;
            this.picture = idToken.picture;
            this.checkRegistation();
          }
  
        });
      }, onSubmitBtnClicked() {
        const payload = {
          isActive: true,
          uid: this.userId,
          name: this.profile.name,
          phone: this.profile.phone,
          ext: "",
          fax: "",
          position: "",
          org: this.profile.org
        }
        axios
          .post('https://api-moph.zensesage.cloud/line/profile', payload)
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
            this.isCalled = error
          })
          .finally(() => this.checkRegistation())
      }, checkRegistation() {
        this.isCalled = "Called"
        
        axios
          .get('https://api-moph.zensesage.cloud/line/profile/'+this.userId)
          .then(response => {
            console.log(response.data)
            if (response.data.isActive) {
              this.profile = response.data;
            }
          })
          .catch(error => {
            console.log(error)
            this.isCalled = error
          })
          .finally(() => this.isChecking = false)
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .page-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
  }
  
  .tripple-dot {
    position: absolute;
    top: 70px;
    width: 100%;
  }
  .blue {
    color: deepskyblue;
  }
  
  .mtop-small {
    margin-top: 20px;
  }
  
  .label-subject {
    text-align: left;
    padding-left: 10px;
  }
  
  .label-text {
    text-align: left;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;
      font-weight: bold;
  }
  .text-box {
    width: 90%;
      height: 35px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      padding-left: 10px;
      font-size: 16px;
  }
  
  .select-box {
    width: 93%;
      height: 43px;
      border-radius: 8px;
      border: 1px solid lightslategrey;
      background-color: white;
      padding-left: 10px;
      font-size: 16px;
  }
  .header-area {
    height: 150px;
    width: 100%;
    /* background-color: red; */
    background-image: url("../assets/headerbg.png");
    background-size: cover;
  }
  
  .btn-request {
    font-size: 16px;
      border-radius: 8px;
      background-color: deepskyblue;
      color: white;
      border: none;
      padding: 10px 15px;
  }
  
  .body-area {
    width: 100%;
    padding-top: 20px;
  }
  
  .profile-photo {
    /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
    border-radius: 50%;
      width: 70px;
      top: 40px;
      left: 50px;
      border: green 1px solid;
      position: absolute;
  }

  .profile-photo-signed {
    border-radius: 50%;
      width: 170px;
      top: 140px;
      left: 50%;
      transform: translate(-50%, -50%);
      border: green 1px solid;
      position: absolute;
  }
  
  .phonebook-photo {
    /* border-radius: 50%; */
    /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
    width: 65px;
      position: absolute;
      top: 45px;
      right: 45px;
  }
  
  .mtop-medium {
    margin-top: 120px;
  }
  .circle-a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 150px; */
    left: calc(40% - 7px);
  }
  
  .circle-b {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 185px; */
    left: calc(50% - 7px);
  }
  
  .circle-c {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    /* top: 70px;
    left: 220px; */
    left: calc(60% - 7px);
  }
  .label-welcome {
    font-size: 28px;
    color: green;
    margin-top: 20px;
  }
  </style>
  