<template>
  <div class="page-container">
    <div class="header-area">
      <div class="tripple-dot">
        <div class="header-text">แจ้งแก้ไขข้อมูล</div>
      </div>
    </div>
    <div v-if="isSent" class="body-area-success">
      <div class="body-success">
        <img class="icon-success" src="../assets/success.png" />
        <div class="label-success">ได้รับคำขอของท่านแล้ว</div>
        <!-- <div class="btn-request" v-on:click="close()">ปิดหน้าต่าง</div> -->
        <div class="footer-success">
          <input  class="mtop-small btn-request" type="submit" @click="reset()" value="ขอแก้ไขเพิ่ม">
          <br />
          <input  class="btn-request-reset" type="submit" @click="close()" value="ปิดหน้าต่าง">
        </div>
      </div>
      
    </div>
    <div v-if="!isSent" class="body-area">
      <div class="box-target">
        <div class="form-area">
          <div class="label-topic">ข้อมูลที่แสดงในระบบ (ข้อมูลเดิม)</div>
          <div class="label-subject mtop-small">ชื่อ - นามสกุล <span class="red">*</span></div>
          <input class="text-box" v-model="phonebook.current.name" type="text" placeholder="ชื่อ นามสกุล">
          <!-- <hr class="mtop-small"> -->
        </div>
      </div>
      <div class="box-change">
        <div class="form-area">
          <div class="label-topic">กรอกข้อมูลที่ต้องการแก้ไข</div>
          <div class="label-subject mtop-small">ชื่อ - นามสกุล</div>
          <input class="text-box" v-model="phonebook.modify.name" type="text" placeholder="ชื่อ นามสกุล">
          <div class="label-subject">เบอร์โทร</div>
          <input class="text-box" v-model="phonebook.modify.phone" type="text" placeholder="เบอร์โทรศัพท์">

          <div class="label-subject">เบอร์โทรศัพท์มือถือ</div>
          <input class="text-box" v-model="phonebook.modify.mobile" type="text" placeholder="เบอร์โทรศัพท์มือถือ">

          <div class="label-subject">เบอร์ภายใน</div>
          <input class="text-box" v-model="phonebook.modify.ext" type="text" placeholder="เบอร์ภายใน">

          <div class="label-subject">เบอร์แฟกซ์</div>
          <input class="text-box" v-model="phonebook.modify.fax" type="text" placeholder="เบอร์แฟกซ์">

          <div class="label-subject">ตำแหน่ง</div>
          <input class="text-box" v-model="phonebook.modify.position" type="text" placeholder="ตำแหน่ง">

          <div class="label-subject">หน่วยงาน</div>
          <select v-model="phonebook.modify.org" class="select-box">
            <option value="">ไม่เปลี่ยนแปลง</option>
            <option value="สำนักปลัดกระทรวงสาธารณสุข">สำนักปลัดกระทรวงสาธารณสุข</option>
            <option value="กรมควบคุมโรค">กรมควบคุมโรค</option>
            <option value="กรมการแพทย์">กรมการแพทย์</option>
            <option value="กรมการแพทย์แผนไทยและการแพทย์ทางเลือก">กรมการแพทย์แผนไทยและการแพทย์ทางเลือก</option>
            <option value="กรมวิทยาศาสตร์การแพทย์">กรมวิทยาศาสตร์การแพทย์</option>
            <option value="กรมสนับสนุนบริการสุขภาพ">กรมสนับสนุนบริการสุขภาพ</option>
            <option value="กรมสุขภาพจิต">กรมสุขภาพจิต</option>
            <option value="กรมอนามัย">กรมอนามัย</option>
            <option value="สำนักงานคณะกรรมการอาหารและยา">สำนักงานคณะกรรมการอาหารและยา</option>
            <option value="รองอธิการบดีด้านบริหาร">รองอธิการบดีด้านบริหาร</option>
            <option value="องค์การเภสัชกรรม">องค์การเภสัชกรรม</option>
            <option value="เขตบริการสุขภาพ เขตที่ 1">เขตบริการสุขภาพ เขตที่ 1</option>
            <option value="เขตบริการสุขภาพ เขตที่ 2">เขตบริการสุขภาพ เขตที่ 2</option>
            <option value="เขตบริการสุขภาพ เขตที่ 3">เขตบริการสุขภาพ เขตที่ 3</option>
            <option value="เขตบริการสุขภาพ เขตที่ 4">เขตบริการสุขภาพ เขตที่ 4</option>
            <option value="เขตบริการสุขภาพ เขตที่ 5">เขตบริการสุขภาพ เขตที่ 5</option>
            <option value="เขตบริการสุขภาพ เขตที่ 6">เขตบริการสุขภาพ เขตที่ 6</option>
            <option value="เขตบริการสุขภาพ เขตที่ 7">เขตบริการสุขภาพ เขตที่ 7</option>
            <option value="เขตบริการสุขภาพ เขตที่ 8">เขตบริการสุขภาพ เขตที่ 8</option>
            <option value="เขตบริการสุขภาพ เขตที่ 9">เขตบริการสุขภาพ เขตที่ 9</option>
            <option value="เขตบริการสุขภาพ เขตที่ 10">เขตบริการสุขภาพ เขตที่ 10</option>
            <option value="เขตบริการสุขภาพ เขตที่ 11">เขตบริการสุขภาพ เขตที่ 11</option>
            <option value="เขตบริการสุขภาพ เขตที่ 12">เขตบริการสุขภาพ เขตที่ 12</option>
          </select>
        </div>
      </div>
      <div class="box-reporter">
        <div class="form-area">
          <div class="label-topic">ข้อมูลผู้แจ้ง</div>
          <div class="label-subject mtop-small">ชื่อ - นามสกุล <span class="red">*</span></div>
          <input class="text-box" v-model="phonebook.reporter.name" type="text" placeholder="ชื่อ นามสกุล">
          <div class="label-subject">เบอร์โทร <span class="red">*</span></div>
          <input class="text-box" v-model="phonebook.reporter.phone" type="text" placeholder="เบอร์โทรศัพท์">
          <div class="label-subject">หน่วยงาน <span class="red">*</span></div>
          <select v-model="phonebook.reporter.org" class="select-box">
            <option value="สำนักปลัดกระทรวงสาธารณสุข">สำนักปลัดกระทรวงสาธารณสุข</option>
            <option value="กรมควบคุมโรค">กรมควบคุมโรค</option>
            <option value="กรมการแพทย์">กรมการแพทย์</option>
            <option value="กรมการแพทย์แผนไทยและการแพทย์ทางเลือก">กรมการแพทย์แผนไทยและการแพทย์ทางเลือก</option>
            <option value="กรมวิทยาศาสตร์การแพทย์">กรมวิทยาศาสตร์การแพทย์</option>
            <option value="กรมสนับสนุนบริการสุขภาพ">กรมสนับสนุนบริการสุขภาพ</option>
            <option value="กรมสุขภาพจิต">กรมสุขภาพจิต</option>
            <option value="กรมอนามัย">กรมอนามัย</option>
            <option value="สำนักงานคณะกรรมการอาหารและยา">สำนักงานคณะกรรมการอาหารและยา</option>
            <option value="รองอธิการบดีด้านบริหาร">รองอธิการบดีด้านบริหาร</option>
            <option value="องค์การเภสัชกรรม">องค์การเภสัชกรรม</option>
            <option value="เขตบริการสุขภาพ เขตที่ 1">เขตบริการสุขภาพ เขตที่ 1</option>
            <option value="เขตบริการสุขภาพ เขตที่ 2">เขตบริการสุขภาพ เขตที่ 2</option>
            <option value="เขตบริการสุขภาพ เขตที่ 3">เขตบริการสุขภาพ เขตที่ 3</option>
            <option value="เขตบริการสุขภาพ เขตที่ 4">เขตบริการสุขภาพ เขตที่ 4</option>
            <option value="เขตบริการสุขภาพ เขตที่ 5">เขตบริการสุขภาพ เขตที่ 5</option>
            <option value="เขตบริการสุขภาพ เขตที่ 6">เขตบริการสุขภาพ เขตที่ 6</option>
            <option value="เขตบริการสุขภาพ เขตที่ 7">เขตบริการสุขภาพ เขตที่ 7</option>
            <option value="เขตบริการสุขภาพ เขตที่ 8">เขตบริการสุขภาพ เขตที่ 8</option>
            <option value="เขตบริการสุขภาพ เขตที่ 9">เขตบริการสุขภาพ เขตที่ 9</option>
            <option value="เขตบริการสุขภาพ เขตที่ 10">เขตบริการสุขภาพ เขตที่ 10</option>
            <option value="เขตบริการสุขภาพ เขตที่ 11">เขตบริการสุขภาพ เขตที่ 11</option>
            <option value="เขตบริการสุขภาพ เขตที่ 12">เขตบริการสุขภาพ เขตที่ 12</option>
          </select>
        </div>
      </div>
      <!-- </div> -->
      <div class="btn-area">
        <input v-bind:disabled="!isEnable()" v-bind:class="enableClass()" class="mtop-small" type="submit"
          @click="onSubmitBtnClicked()" value="ส่งแบบฟอร์ม">
      </div>
    </div>

  </div>
</template>
  
<script>
import liff from '@line/liff';
import axios from 'axios';

export default {
  name: 'EditComponent',
  props: {
    msg: String
  }, data() {
    return {
      userId: '', // sub
      name: '',
      picture: 'https://profile.line-scdn.net/0hT7vICrHcCxhLMBhVyzF0T3d1BXU8Hg1QM1dBeGo1BipjVUQdd19Eejs1BiEyCU8aIFRCLmYzUCtm',
      isUnauthorize: false,
      isSent: false,
      isError: false,
      phonebook: {
        userId: "",
        current: {
          name: "",
        }, modify: {
          name: "",
          org: "",
          ext: "",
          phone: "",
          mobile: "",
          fax: "",
          section: "",
          position: ""
        }, reporter: {
          name: "",
          org: "",
          phone: "",
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      liff.init({
        liffId: '2001631857-bMdnrM7o', // Use own liffId
        withLoginOnExternalBrowser: true,
      }).then(() => {
        const idToken = liff.getDecodedIDToken();
        console.log(idToken); // print decoded idToken object
        if (idToken) {
          this.userId = idToken.sub;
          this.phonebook.userId = this.userId;
          this.name = idToken.name;
          this.picture = idToken.picture;
        }
      });
    }, onSubmitBtnClicked() {
      axios
        .post('https://api-moph.zensesage.cloud/line/edit', this.phonebook)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
          this.isError = true;
        })
        .finally(() => this.isSent = true)
    }, isEnable() {
      if (this.phonebook.current.name != "" && this.phonebook.reporter.name != "" && this.phonebook.reporter.phone != "" && this.phonebook.reporter.org != "") {
        console.log("Enabled")
        return true;
      }
      console.log("NO")
      return false;
    }, enableClass() {
      if (this.isEnable()) {
        return "btn-request"
      } else {
        return "btn-request-disable"
      }
    }, reset() {
      this.phonebook.current.name = "";
      this.phonebook.modify.name= "";
      this.phonebook.modify.org= "";
      this.phonebook.modify.ext= "";
      this.phonebook.modify.phone= "";
      this.phonebook.modify.mobile= "";
      this.phonebook.modify.position= "";
      this.phonebook.modify.fax= "";
      this.phonebook.modify.section= "";
      this.isError = false;
      this.isSent = false;
    }, close() {
      liff.closeWindow();
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-text {
  color: white;
  font-size: 32px;
}

.page-container {
  width: 100%;
  height: 100%;
  top: 0;
  position: relative;
}

.tripple-dot {
  position: absolute;
  top: 20px;
  width: 100%;
}

.blue {
  color: deepskyblue;
}

.mtop-small {
  margin-top: 20px;
}

.label-subject {
  /* text-align: left; */
  /* padding-left: 10px; */
  margin-bottom: 10px;
  margin-top: 20px;
}

.label-text {
  text-align: left;
  padding-left: 15px;
  margin-top: 15px;
  font-size: 13px;
  font-weight: bold;
}

.text-box {
  width: 97%;
  height: 35px;
  border-radius: 8px;
  border: 1px solid lightslategrey;
  padding-left: 10px;
  font-size: 16px;
}

.select-box {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  color: black;
  border: 1px solid lightslategrey;
  background-color: white;
  padding-left: 10px;
  font-size: 16px;
}

.header-area {
  height: 85px;
  width: 100%;
  /* background-color: red; */
  background-image: url("../assets/headerbg.png");
  background-size: cover;
}

.btn-request {
  font-size: 16px;
  width: 50%;
  border-radius: 8px;
  background-color: deepskyblue;
  color: white;
  border: none;
  padding: 10px 15px;
  position: relative;
  margin-bottom: 20px;
}

.btn-request-reset {
  font-size: 16px;
  width: 50%;
  border-radius: 8px;
  background-color: rgb(255, 0, 0);
  color: white;
  border: none;
  padding: 10px 15px;
  position: relative;
  margin-bottom: 10px;
}

.btn-request-disable {
  font-size: 16px;
  width: 80%;
  border-radius: 8px;
  background-color: rgb(179, 179, 179);
  color: white;
  border: none;
  padding: 10px 15px;
  position: relative;
  margin-bottom: 20px;
}

.body-area {
  width: 100%;
  /* padding: 20px; */
  text-align: left;
}

.body-area-success {
  width: 100%;
  /* padding: 20px; */
  text-align: center;
}

.profile-photo {
  /* border-radius: 50%;
    width: 70px;
    margin-top: 40px;
    margin-left: -200px;
    border: green 1px solid; */
  border-radius: 50%;
  width: 70px;
  top: 40px;
  left: 50px;
  border: green 1px solid;
  position: absolute;
}

.form-area {
  padding: 20px;
}

.phonebook-photo {
  /* border-radius: 50%; */
  /* width: 65px;
    margin-top: 43px;
    margin-left: 150px;
    position: absolute; */
  width: 65px;
  position: absolute;
  top: 45px;
  right: 45px;
}

.circle-a {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
    left: 150px; */
  left: calc(40% - 7px);
}

.circle-b {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
    left: 185px; */
  left: calc(50% - 7px);
}

.circle-c {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  /* top: 70px;
    left: 220px; */
  left: calc(60% - 7px);
}

.label-topic {
  font-weight: bold;
}

.btn-area {
  text-align: center;
}

.box-change {
  background-color: rgb(255, 246, 233);
}

.box-target {
  background-color: azure;
  padding-bottom: 20px;
}

.box-reporter {
  background-color: rgb(249, 255, 239);
}

.red {
  color: red;
}

.icon-success {
  width: 128px;
}


.body-success {
  margin-top: 100px;
  text-align: center;
}

.label-success {
  margin-top: 20px;
}

.footer-success {
  /* margin: 15px 25px; */
  margin-top: 100px;
  text-align: center;
}
</style>
  